import { Box, BoxProps } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  GetAppButton,
  GetAppTitle,
  HelpGifOpenExtBrowser,
  HelpGifOpenExtBrowserMp4,
  HelpGifOpenShareAddHomescreen,
  HelpGifOpenShareAddHomescreenMp4,
} from "src/assets";
import { useAppContext } from "src/main/constants";
import { useGtmTracker } from "src/main/hooks";
import { EvtAction } from "src/main/hooks/useGtmTracker";
import { createStyles } from "src/main/utils";
import HelpGifDialog from "../HelpGifDialog";

interface BeforeInstallEvent {
  prompt: () => void;
  userChoice: Promise<any> | null;
}

interface InstallBannerProps extends BoxProps {}

const InstallBanner: React.FC<InstallBannerProps> = (props) => {
  const { ...rest } = props;
  const { sendEvent, sendSingularEvent } = useGtmTracker();
  const { isFbIab = false, isLineIab = false, isIosDevice = false, isFullScreen = false } = useAppContext();
  const [requestedInstall, setRequestedInstall] = useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallEvent | null | undefined>();

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e as unknown as BeforeInstallEvent);
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, [setDeferredPrompt]);

  useEffect(() => {
    if (requestedInstall && (isFbIab || isLineIab)) {
      sendSingularEvent(EvtAction.ShowOpenExtBrowser);
    }
  }, [sendSingularEvent, requestedInstall, isFbIab, isLineIab]);

  useEffect(() => {
    if (requestedInstall && !isFbIab && !isLineIab && isIosDevice) {
      sendSingularEvent(EvtAction.ShowIosAddToHome);
    }
  }, [sendSingularEvent, requestedInstall, isFbIab, isLineIab, isIosDevice]);

  const canInstall = deferredPrompt !== null && deferredPrompt !== undefined;

  if (!canInstall && !isFbIab && !isLineIab && !isIosDevice) return null;
  if (isFullScreen) return null;

  const install = () => {
    (async () => {
      deferredPrompt?.prompt();
      const { outcome } = await deferredPrompt?.userChoice;
      setDeferredPrompt(null);
      if (outcome === "accepted") {
        // success
        sendEvent(EvtAction.InstallSuccess);
      } else if (outcome === "dismissed") {
        // dismissed
      }
    })();
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation?.();
    e.preventDefault?.();
    sendEvent(EvtAction.ClickInstallBanner);
    if (isFbIab || isLineIab || isIosDevice) {
      setRequestedInstall(true);
    } else {
      install();
    }
  };

  const handleClose = (e) => {
    e.stopPropagation?.();
    e.preventDefault?.();
    setRequestedInstall(false);
  };

  return (
    <Box
      sx={styles.root}
      {...rest}
    >
      <Box sx={styles.container}>
        <div
          onClick={handleClick}
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "70px" }}
        >
          <img
            height={56}
            src={GetAppTitle}
            alt="get app"
          />
          <img
            src={GetAppButton}
            alt="get app button"
            height={52}
          />
        </div>
      </Box>
      <HelpGifDialog
        title="ดาวน์โหลด SSS268.net!"
        src={HelpGifOpenExtBrowser}
        fallbackSrc={HelpGifOpenExtBrowserMp4}
        open={requestedInstall && isLineIab}
        onClose={(event) => handleClose(event)}
      />
      <HelpGifDialog
        title="ดาวน์โหลด SSS268.net!"
        src={HelpGifOpenExtBrowser}
        fallbackSrc={HelpGifOpenExtBrowserMp4}
        open={requestedInstall && isFbIab}
        onClose={(event) => handleClose(event)}
      />
      <HelpGifDialog
        title="ดาวน์โหลด SSS268.net!"
        src={HelpGifOpenShareAddHomescreen}
        fallbackSrc={HelpGifOpenShareAddHomescreenMp4}
        open={requestedInstall && !isFbIab && !isLineIab && isIosDevice}
        onClose={(event) => handleClose(event)}
      />
    </Box>
  );
};

const styles = createStyles({
  root: {
    marginX: "-16px",
    "@media (min-width: 600px)": {
      marginX: "-24px",
    },
    position: "sticky",
    top: 0,
    zIndex: 100,
  },
  container: {
    height: "70px",
    background: "linear-gradient(80deg, rgb(42,0,67) 0%, rgb(90,9,145) 60%, rgb(137,17,223) 100%)",
    paddingLeft: "8px",
    paddingRight: "8px",

    width: "100%",
    zIndex: 15,
  },
});

export default InstallBanner;
