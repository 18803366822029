import { VisuallyHidden } from "@99tech/ui";
import { Box } from "@mui/material";
import React from "react";
import { Character, EnterSite, Logo } from "src/assets";
import { FloatingBanner } from "src/main/components";
import { createStyles } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">SSS268</VisuallyHidden>
      <Box sx={{ position: "relative", width: 360, textAlign: "center" }}>
        <img
          alt="logo"
          src={Logo}
          style={{ width: 360 }}
        />
      </Box>

      <img
        alt="enter site"
        src={EnterSite}
        style={{ width: 160, marginTop: -50 }}
      />

      <Box sx={styles.images}>
        <img
          alt="character"
          src={Character}
          style={{
            width: 350,
            top: -40,
            left: 0,
            right: 0,
            margin: "0 auto",
          }}
        />
      </Box>

      <FloatingBanner />
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "10px",
    pb: "20px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
    background: "radial-gradient(51.1% 73.38% at 50% 0%, #FFA360 0%, rgba(255, 30, 0, 0.00) 100%)",
  },
  images: {
    zIndex: 10,
    position: "relative",
    width: "360px",
    height: "320px",
    img: {
      position: "absolute",
    },
  },
  footer: {
    zIndex: 0,
    position: "fixed",
    bottom: "0",
    width: "100vw",
    height: "200px",
    flexShrink: 0,
    background: "radial-gradient(800px 100% at 50% 100%, #FFF9C1 0%, #21966C 19%, rgba(0, 0, 0, 0.00) 50%)",
  },
});

export default LandingPage;
