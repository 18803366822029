import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const DEFAULT_URL = "";

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const ENTER_SITE_URL = "https://a.vtrk8.com/73d87a24-7337-4fca-92f9-9a29ac27b3bb";

export const FLOATING_BANNER_URL = "https://sss268tha.com/cs";

export * from "./codes";
export * from "./context";
